<template>
  <div>
    <div class="card" style="margin-bottom: 15px">
      <el-page-header
        @back="goBack"
        :content="type === 'add' ? '新增入库单' : '编辑入库单'"
      >
      </el-page-header>
    </div>
    <div class="content">
      <div class="box">
        <el-form :model="form" ref="form" :rules="rules">
          <div
            :class="isFirst ? 'firstLine' : 'adopt'"
            style="border-bottom: none"
          >
            <div class="wareTitle">
              <div class="wareNum selectNum">1</div>
              填写入库货品的基本信息
            </div>
            <div class="form" v-show="isFirst === true">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item
                    label="选择品类"
                    :label-width="formLabelWidth"
                    prop="goods_type_id"
                  >
                    <el-select
                      v-model="form.goods_type_id"
                      placeholder="请选择品类"
                      style="width: 80%"
                      @change="handleGoodsType"
                      :disabled="type === 'add' ? false : true"
                    >
                      <template>
                        <el-option-group label="商品分类">
                          <el-option
                            v-for="item in attrOneList"
                            :label="item.goods_type_name"
                            :value="item.goods_type_id"
                            :key="item.goods_type_id"
                          >
                            <span
                              :style="{ marginLeft: item.span * 8 + 'px' }"
                              >{{ item.goods_type_name }}</span
                            >
                          </el-option>
                        </el-option-group>
                        <!-- <el-option-group label="旧料分类">
                          <el-option
                            v-for="item in attrTwoList"
                            :label="item.goods_type_name"
                            :value="item.goods_type_id"
                            :key="item.goods_type_id"
                          >
                            <span
                              :style="{ marginLeft: item.span * 8 + 'px' }"
                              >{{ item.goods_type_name }}</span
                            >
                          </el-option>
                        </el-option-group> -->
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="入库单号" :label-width="formLabelWidth">
                    <el-input
                      v-model="form.entry_number"
                      autocomplete="off"
                      placeholder="请输入入库单号"
                      style="width: 80%"
                      :disabled="type === 'add' ? false : true"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="备注信息" :label-width="formLabelWidth">
                    <el-input
                      v-model="form.note"
                      type="textarea"
                      autocomplete="off"
                      placeholder="请输入备注信息"
                      style="width: 80%"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="选择仓库"
                    :label-width="formLabelWidth"
                    prop="warehouse_id"
                  >
                    <el-select
                      v-model="form.warehouse_id"
                      placeholder="请选择仓库"
                      filterable
                      style="width: 80%"
                    >
                      <template v-for="(item, index) in wareHouseList">
                        <el-option
                          :label="item.warehouse_name"
                          :value="item.warehouse_id"
                          :key="index"
                        ></el-option>
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="选择供应商"
                    :label-width="formLabelWidth"
                  >
                    <el-select
                      v-model="form.supplier_id"
                      placeholder="请选择供应商"
                      filterable
                      style="width: 80%"
                    >
                      <template v-for="(item, index) in supplierList">
                        <el-option
                          :label="item.supplier_name"
                          :value="item.supplier_id"
                          :key="index"
                        ></el-option>
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="type === 'sockEdit'">
                  <el-form-item label="创建时间" :label-width="formLabelWidth">
                    <el-date-picker
                      value-format="yyyy-MM-dd HH:mm:ss"
                      v-model="form.create_time"
                      type="datetime"
                      style="width: 80%"
                      placeholder="选择创建时间"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="type === 'sockEdit'">
                  <el-form-item label="审核时间" :label-width="formLabelWidth">
                    <el-date-picker
                      value-format="yyyy-MM-dd HH:mm:ss"
                      v-model="form.audit_time"
                      type="datetime"
                      style="width: 80%"
                      placeholder="选择审核日期"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <div style="width: 200px; margin: 30px auto 0 auto">
                <el-button
                  type="primary"
                  style="width: 120px; height: 40px"
                  @click="toSecond"
                  >下一步</el-button
                >
              </div>
            </div>
          </div>
        </el-form>

        <div
          class="wareLine"
          :style="isSecond ? 'background-color: white;height:100%' : null"
          :class="isSecond ? null : 'adopt'"
          style="border-bottom: none"
        >
          <div :style="isSecond ? 'background-color: white;' : null">
            <div class="wareTitle">
              <div class="wareNum" :class="isFirst ? '' : 'selectNum'">2</div>
              <div :style="isSecond ? 'line-height:25px' : null">
                <div>填写入库的货品详细资料</div>
                <div v-if="isSecond">
                  1. 扫描枪扫描货品入库或者手动【新增行】填写货号，
                  货号输入框中最后一行按下Enter回车键也可增加行；可在表格中填写货品详细信息
                </div>
                <div v-if="isSecond">
                  2.
                  标签价手动填入则按照填入的标签价计算，否则系统自动按倍率计算（计价类素金按克销售除外）
                </div>
              </div>
            </div>
            <div
              v-if="isSecond"
              style="display: flex; margin-top: 30px; height: 50px"
            >
              <el-button
                type="primary"
                icon="el-icon-plus"
                @click="handleAddTable"
                style="width: 120px; height: 40px; margin-right: 15px"
                >新增行</el-button
              >
              <el-upload
                :action="
                  baseUrl + 'store/goods.EntryCtl/importGoodsDataByUploadFile'
                "
                :data="{
                  goods_type_id: form.goods_type_id,
                  goods_number_type: goods_type,
                  getFileTitle: 1,
                }"
                name="datafile"
                :headers="{
                  authorization: token,
                }"
                :show-file-list="false"
                :on-success="handleGoodsSuccess"
                style="
                  width: 120px;
                  height: 40px;
                  margin-right: 15px;
                  margin-top: -10px;
                "
              >
                <el-button type="primary" plain>批量导入货品</el-button>
              </el-upload>

              <el-button
                icon="el-icon-upload"
                type="primary"
                plain
                style="width: 140px; height: 40px"
                @click="getImportFile"
                >下载导入模板</el-button
              >
              <el-button
                type="primary"
                plain
                style="height: 40px"
                @click="handleBatchEdit"
                >批量修改</el-button
              >
              <el-button
                type="primary"
                plain
                style="height: 40px"
                @click="handleBatchCopy"
                >批量复制行</el-button
              >
            </div>
            <u-table
              v-if="isSecond"
              v-loading="tableLoading"
              ref="plTable"
              :data="tableList"
              use-virtual
              :row-height="50"
              :data-changes-scroll-top="false"
              :cell-class-name="getCellIndex"
              @selection-change="handleSelectionChange"
              border
              :height="500"
            >
              <u-table-column
                type="selection"
                width="55"
                align="center"
                :selectable="selectable"
              />
              <u-table-column
                fixed="right"
                align="center"
                label="操作"
                width="100"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="
                      handleDele(scope.row.index, scope.row.entry_goods_id)
                    "
                    >删除</el-button
                  >
                </template>
              </u-table-column>
              <u-table-column
                width="80"
                label="序号"
                fixed="left"
                align="center"
                ><template slot-scope="scope">{{
                  scope.row.index
                }}</template></u-table-column
              >
              <u-table-column
                v-for="(item, index) in rowList"
                v-if="item.hide != 1"
                align="center"
                :key="index"
                :prop="item.prop"
                :label="(item.is_mandatory === 20 ? '*' : '') + item.label"
                :width="rowList.length > 8 ? 200 : null"
                :show-overflow-tooltip="true"
                :resizable="true"
              >
                <template slot="header">
                  <div>
                    <span style="color: #f56c6c" v-if="item.is_mandatory === 20"
                      >*</span
                    >{{ item.label }}
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-input
                    clearable
                    v-model="scope.row[item.prop]"
                    :placeholder="'请输入' + item.attr_name"
                    :disabled="
                      (type === 'edit' &&
                        scope.row.usable_count !== scope.row.num) ||
                      (type === 'sockEdit' &&
                        scope.row.usable_count !== scope.row.num)
                        ? true
                        : false
                    "
                    :id="item.prop + scope.row.index + scope.column.index"
                    @keyup.enter.native="
                      handleGoodsNumber(
                        $event,
                        item.prop,
                        scope.row.index,
                        scope.column.index
                      )
                    "
                    :autofocus="true"
                    v-if="item.attr_fields === 'goods_number'"
                  ></el-input>
                  <el-input
                    clearable
                    v-model="scope.row[item.prop]"
                    :placeholder="'请输入' + item.attr_name"
                    :disabled="
                      (type === 'edit' &&
                        scope.row.usable_count !== scope.row.num) ||
                      (type === 'sockEdit' &&
                        scope.row.usable_count !== scope.row.num)
                        ? true
                        : false
                    "
                    :id="item.prop + scope.row.index + scope.column.index"
                    @input="
                      handleAttrInput(
                        $event,
                        item.prop,
                        scope.row.index,
                        scope.row,
                        1
                      )
                    "
                    @keyup.enter.native="
                      handleKeyBlur(
                        $event,
                        item.prop,
                        scope.row.index,
                        scope.column.index
                      )
                    "
                    :autofocus="true"
                    v-if="
                      item.type === 10 && item.attr_fields !== 'goods_number'
                    "
                  ></el-input>

                  <el-select
                    v-model="scope.row[item.prop]"
                    :placeholder="'请选择' + item.attr_name"
                    :disabled="
                      (type === 'edit' &&
                        scope.row.usable_count !== scope.row.num) ||
                      (type === 'sockEdit' &&
                        scope.row.usable_count !== scope.row.num)
                        ? true
                        : false
                    "
                    :id="item.prop + scope.row.index + scope.column.index"
                    v-if="item.type === 20"
                  >
                    <template v-for="items in item.val_list">
                      <el-option
                        :label="items.attr_value"
                        :value="items.attr_value"
                        :key="items.attr_value_id"
                      >
                      </el-option>
                    </template>
                  </el-select>
                  <!-- 新增数值类型 -->
                  <el-input
                    clearable
                    v-model="scope.row[item.prop]"
                    :disabled="
                      (type === 'edit' &&
                        scope.row.usable_count !== scope.row.num) ||
                      (type === 'sockEdit' &&
                        scope.row.usable_count !== scope.row.num)
                        ? true
                        : false
                    "
                    :placeholder="'请输入' + item.attr_name"
                    type="number"
                    :id="item.prop + scope.row.index + scope.column.index"
                    @input="
                      handleAttrInput(
                        $event,
                        item.prop,
                        scope.row.index,
                        scope.row,
                        1
                      )
                    "
                    @keyup.enter.native="
                      handleKeyBlur(
                        $event,
                        item.prop,
                        scope.row.index,
                        scope.column.index
                      )
                    "
                    :autofocus="true"
                    v-if="item.type === 30"
                  ></el-input>
                  <!-- 新增自动计算 -->
                  <el-input
                    clearable
                    v-model="scope.row[item.prop]"
                    :placeholder="'自动计算'"
                    :autofocus="true"
                    disabled
                    v-if="item.type === 40"
                  ></el-input>
                </template>
              </u-table-column>
            </u-table>
          </div>
        </div>

        <div v-if="isSecond" style="width: 250px; margin: 20px auto 30px auto">
          <el-button type="primary" plain style="width: 120px" @click="toFirst"
            >上一步</el-button
          >
          <el-button
            type="primary"
            :loading="submitLoading"
            style="width: 120px"
            v-if="type === 'add'"
            @click="submit('form')"
            >入库完成</el-button
          >
          <el-button
            type="primary"
            :loading="submitLoading"
            style="width: 120px"
            v-if="type === 'edit'"
            @click="submit('form')"
            >重新提交审核</el-button
          >
          <el-button
            type="primary"
            :loading="submitLoading"
            style="width: 120px"
            @click="submit('form')"
            v-if="type === 'sockEdit'"
            >保存并锁定</el-button
          >
        </div>
        <div
          class="wareLine"
          :style="isThird ? 'background-color: white;height:100%' : null"
        >
          <div class="wareTitle">
            <div class="wareNum" :class="!isThird ? '' : 'selectNum'">3</div>
            入库完成
          </div>
          <div style="height: 300px; padding-bottom: 40px" v-if="isThird">
            <div style="margin: 50px auto 0 auto; width: 150px; height: 100px">
              <img :src="auditSuccess" />
            </div>
            <div style="text-align: center; margin-top: 30px">
              您的入库申请已提交成功
            </div>
            <div style="text-align: center">
              当前入库单号为：<span style="color: #2791ff">{{
                entry_number
              }}</span>
            </div>
            <div style="width: 100px; margin: 0 auto 20px auto">
              <el-button type="primary" plain @click="goBack"
                >返回到列表</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="templateVisiable"
      title="选择对应表头模板"
      width="30%"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="cancelTemplateTable"
    >
      <div>
        <el-table :data="templateList" stripe style="border: 1px solid #e8e8e8">
          <el-table-column align="center" prop="template_name" label="模板名称">
          </el-table-column>
          <el-table-column align="center" prop="action" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleChooseTemplate(scope.row)"
                >选择</el-button
              >
              <el-button type="text" @click="handleDelTemplate(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagina">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            :total="templateTotal"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
        <div style="text-align: center; margin-top: 15px">
          <el-button type="primary" plain @click="handelAddTemplate"
            >新增表头模板</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="tableVisiable"
      :title="templateName"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="cancelTable"
    >
      <div v-if="templateName === '新增表头模板'">
        <el-form @submit.native.prevent>
          <el-form-item label="模板名称" label-width="80px" required>
            <el-input
              v-model="template_name"
              autocomplete="off"
              placeholder="请输入模板名称"
              style="width: 80%"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="display: flex; margin: 0 auto; width: 100%">
        <div style="margin-right: 10px; width: 50%">
          <div class="title">
            <div class="span"></div>
            系统表头
          </div>
          <div
            style="
              border-left: 1px solid #e8e8e8;
              border-right: 1px solid #e8e8e8;
              border-top: 1px solid #e8e8e8;
            "
          >
            <el-table :data="fieldList" stripe>
              <el-table-column align="center" prop="attr_name" label="表头字段">
                <template slot-scope="scope">
                  <span style="color: red">{{
                    scope.row.is_mandatory === 20 ? "*" : ""
                  }}</span
                  >{{ scope.row.attr_name }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div style="width: 50%">
          <div class="title">
            <div class="span"></div>
            设置文件表头
          </div>
          <div
            style="
              border-left: 1px solid #e8e8e8;
              border-right: 1px solid #e8e8e8;
              border-top: 1px solid #e8e8e8;
            "
          >
            <el-table :data="fieldList" stripe>
              <el-table-column align="center" label="表头字段">
                <template slot-scope="scope">
                  <el-select
                    v-model="goodsinfo_map[fieldList[scope.$index].attr_fields]"
                    @change="handleDisableFileList"
                    style="width: 100%"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="items in downFiledList"
                      :label="items.name"
                      :value="items.name"
                      :key="items.name"
                    >
                    </el-option
                  ></el-select>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin-top: 15px">
        <el-button
          type="primary"
          :loading="loading"
          @click="handleConfigTemplate(10)"
          v-if="templateName === '新增表头模板'"
          >保存</el-button
        >
        <el-button
          type="primary"
          :loading="loading"
          @click="handleConfigTemplate(20)"
          v-if="templateName === '新增表头模板'"
          >保存并使用模板</el-button
        >
        <el-button
          type="primary"
          @click="handleSureTable(10)"
          :loading="loading"
          v-if="templateName !== '新增表头模板'"
          >使用</el-button
        >
        <el-button
          type="primary"
          @click="handleSureTable(20)"
          :loading="loading"
          v-if="templateName !== '新增表头模板'"
          >使用并保存模板</el-button
        >
      </div>
    </el-dialog>
    
    <el-dialog
      title="批量修改"
      :visible.sync="batchEditVisiable"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="closeBatchEdit"
    >
      <el-form>
        <el-row :gutter="20">
          <template v-for="(item, index) in rowList">
            <el-col :span="10" :key="index">
              <el-form-item
                :label="item.attr_name"
                :label-width="formLabelWidth"
              >
                <el-input
                  v-model="batchEditForm[item.prop]"
                  v-if="item.type === 10 || item.type === 30"
                  :placeholder="'请输入' + item.attr_name"
                  @input="
                    handleAttrInput($event, item.prop, 1, batchEditForm, 2)
                  "
                  style="width: 100%"
                />
                <el-select
                  v-model="batchEditForm[item.prop]"
                  :placeholder="'请选择' + item.attr_name"
                  v-if="item.type === 20"
                  style="width: 100%"
                  filterable
                >
                  <template v-for="items in item.val_list">
                    <el-option
                      :label="items.attr_value"
                      :value="items.attr_value"
                      :key="items.attr_value_id"
                    >
                    </el-option>
                  </template>
                </el-select>
                <el-input
                  clearable
                  v-model="batchEditForm[item.prop]"
                  :placeholder="'自动计算'"
                  :autofocus="true"
                  disabled
                  v-if="item.type === 40"
                ></el-input>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </el-form>
      <div style="text-align: right; margin-top: 15px">
        <el-button type="primary" plain @click="closeBatchEdit">取消</el-button>
        <el-button type="primary" @click="handleBatchEditCommit"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="批量复制"
      :visible.sync="batchCopyVisiable"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="closeBatchCopy"
      width="25%"
    >
      <el-form style="margin-left: 20px">
        <el-form-item label="行数" label-width="80"
          ><el-input
            placeholder="请输入行数"
            v-model="copyNum"
            style="width: 50%"
        /></el-form-item>
      </el-form>
      <div style="text-align: right; margin-top: 15px">
        <el-button type="primary" plain @click="closeBatchCopy">取消</el-button>
        <el-button type="primary" @click="handleBatchCopyCommit"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import auditSuccess from "../../../../../assets/images/formAbout/auditSuccess.png";
import { getListReq } from "@/api/goods/goodsConfig/classify";
import { validatorDecimal } from "../../../../../utils/reg";

import {
  getAttrList,
  getGoodsTypeList,
  getSupplierList,
  getWarehouseList,
  createWareHouse,
  getEntryNumber,
  getImportFile,
  getEntryInfo,
  getEntryGoodsList,
  updateEntry,
  delEntryGoods,
  getEntryGoodsInfoByGoodsNumber,
  importGoodsDataByUploadFile,
  getGoodsCalcuReq,
  templateList,
  getTemplateInfo,
  addTemplateInfo,
  reverseCheckEntry,
} from "@/api/goods/goodsWarehousing/warehousing";

import Cookies from "js-cookie";
import storage from "good-storage";
import Detail from "../../../goodsHandler/change/Detail.vue";
import numeral from "numeral";

export default {
  components: { Detail },
  data() {
    return {
      setCategoryname:"",
      loading: false,
      noWatch: false,
      timer: null, // 节流标识
      formLabelWidth: "150px",
      isFirst: true,
      isSecond: false,
      auditSuccess: auditSuccess,
      isThird: false,
      form: {},
      wareHouseList: [],
      supplierList: [],
      attrOneList: [],
      attrTwoList: [],
      tableLoading: false,
      tableList: [],
      fieldList: [],
      goodsinfo_map: {},
      downFiledList: [],
      rowList: [],
      filed: {},
      batchEditForm: {},
      selectGoodsList: [],
      tableTotal: 0,
      coordinate: { column: -1, row: -1 },
      entry_number: "",
      tableHeight: 0,
      token: "",
      copyNum: null,
      page: 1,
      submitLoading: false,
      tableVisiable: false,
      templateVisiable: false,
      batchEditVisiable: false,
      batchCopyVisiable: false,
      templateName: "新增表头模板",
      template_name: "",
      templateList: [],
      templateTotal: 0,
      customerTitle: [],
      rules: {
        goods_type_id: [
          {
            required: true,
            message: "请选择品类",
            trigger: "change",
          },
        ],
        warehouse_id: [
          {
            required: true,
            message: "请选择仓库",
            trigger: "change",
          },
        ],
      },
      goodsTypeInfo: {},
      goodsTypeName: "",
      baseUrl: "",
      goods_type_import_id: 0,
    };
  },
  props: {
    goods_type: Number,
    entry_id: Number,
    type: String,
  },
  watch: {
    tableList: {
      handler(val) {
        if (!this.isSecond) {
          return;
        }
        if (this.noWatch) {
          return;
        }
        // console.log("鹏");
        val.forEach((row, index) => {
          this.rowList.forEach((item) => {
            if (item.type === 40) {
              let m = 0;
              const cacluArray = item.caclu_field_string.split(",");
              cacluArray.forEach((data) => {
                if (row[data] || row[data] === 0 || row[data] === "0") {
                  m += 1;
                }
                if (m === cacluArray.length - 1) {
                  // 公式内的所有值都存在时or等于0时才能计算
                  const result = numeral(eval(item.caclu_font)).format(
                    "0.0000"
                  );
                  const newRow = { ...row, [item.attr_fields]: result };
                  this.noWatch = true;
                  this.$set(this.tableList, index, newRow);
                } else {
                  const result = "";
                  const newRow = { ...row, [item.attr_fields]: result };
                  this.noWatch = true;
                  this.$set(this.tableList, index, newRow);
                }
              });
            }
          });
        });
      },
      deep: true,
    },
  },
  activated() {},
  created() {
    this.token = storage.session.get("token") || Cookies.get("token");
    this.getGoodsTypeOneList();
    // this.getGoodsTypeTwoList();
    this.getWarehouseList();
    this.getSupplierList();
    const env = process.env.NODE_ENV;
    const host = window.location.host;
    if (env === "development") {
      this.baseUrl = "https://api.test.etouch.vip/";
    } else {
      if (host === "edianbao.test.etouch.vip") {
        this.baseUrl = "https://api.test.etouch.vip/";
      }
      if (host === "www.etouch.top") {
        this.baseUrl = "https://api.etouch.top/";
      }
      if (host === "edianbao.pretest.etouch.vip") {
        this.baseUrl = "https://api.pretest.etouch.vip/";
      }
    }
    if (this.type === "edit" || this.type === "sockEdit") {
      this.getEntryInfo();
    }
  },
  mounted() {
    this.tableHeight = 400; // 动态设置高度
  },
  methods: {
    selectable(row, index) {
      if (row.usable_count !== row.num) {
        return false;
      } else {
        return true;
      }
    },
    handleSelectionChange(val) {
      this.selectGoodsList = val;
    },
    /*
     *   获取品类下拉数据
     */
    getGoodsTypeOneList() {
      getListReq({ type: [10] }).then((res) => {
        if (res.code === 1) {
          this.attrOneList = this.handleArr(res.data);
          this.changeGoodsType(res.data);
        }
      });
    },
    /*
     *  将品类多维数组转为对象
     */
    cancelTable() {
      this.tableVisiable = false;
      this.templateVisiable = true;
      this.loading = false;
      this.goodsinfo_map = {};
      this.getTemplateList();
    },
    changeGoodsType(data) {
      data.forEach((item) => {
        this.goodsTypeInfo[item.goods_type_id] = item.goods_type_name;
        if (item.children) {
          this.changeGoodsType(item.children);
        }
      });
    },
    /*
     *  获取入库单详情
     */
    getEntryInfo() {
      getEntryInfo({ entry_id: this.entry_id }).then((res) => {
        if (res.code === 1) {
          this.getAttrList(res.data.goods_type_id, res.data.goods_number_type);
          this.form = res.data;
          this.entry_number = res.data.entry_number;
        }
      });
    },
    /*
     *  获取品类数据
     */
    getEntryGoodsList() {
      getEntryGoodsList({
        entry_id: this.entry_id,
        page: 0,
        page_num: 15,
        field_is_show: 1,
      }).then((res) => {
        if (res.code === 1) {
          this.tableList = res.data.list;
        }
      });
    },
    handleKeydown(e) {
      // 通过正则过滤小数点后两位
      e.target.value = e.target.value.match(/^\d*(\.?\d{0,1})/g)[0] || null;
    },
    /*
     *  获取货号
     */
    handleGoodsNumber(event, data, index, column) {
      const list = this.tableList;
      if (event.keyCode === 13) {
        this.coordinate = {
          column: -1,
          row: -1,
        };

        if (this.goods_type === 20) {
          const data = {
            goods_number: event.target.value,
            goods_type_id: this.form.goods_type_id,
            goods_number_type: this.goods_type,
          };
          getEntryGoodsInfoByGoodsNumber(data).then((res) => {
            if (res.code === 1) {
              if (res.data.length !== 0) {
                this.tableList = list.map((item, i) => {
                  if (i + 1 === index) {
                    item = res.data;
                  }
                  return item;
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.msg,
                });
              }
            }
          });
        }
        this.handleKeyBlur(event, data, index, column);
      }
    },
    /*
     *  回车换行，光标聚焦
     */
    handleKeyBlur(event, data, index, column) {
      if (index === this.tableList.length) {
        this.tableList.push({
          ...this.filed,
        });
      }
      const coordinate = { ...this.coordinate };
      coordinate.row = index + 1;
      coordinate.column = column;
      this.coordinate = coordinate;
      this.$nextTick(() => {
        try {
          const dom = document.getElementById(
            data + coordinate.row + coordinate.column
          );
          dom.focus();
        } catch (error) {}
      });
    },
    /*
     *  获取输入框的值
     */
    handleAttrInput(event, data, index, row, type) {
      const list = this.tableList;
      if (data === "price_times") {
        if (type === 1) {
          this.tableList = list.map((item, i) => {
            if (i + 1 === index) {
              if (item.cost_price) {
                item.sale_price = Number(
                  (item.price_times * item.cost_price).toFixed(2)
                );
              }
            }

            return item;
          });
        } else {
          if (this.batchEditForm.cost_price) {
            this.batchEditForm.sale_price = Number(
              (
                this.batchEditForm.price_times * this.batchEditForm.cost_price
              ).toFixed(2)
            );
          }
        }
      }

      if (data === "cost_price") {
        if (type === 1) {
          this.tableList = list.map((item, i) => {
            if (i + 1 === index) {
              if (item.price_times && !item.sale_price) {
                item.sale_price = (item.price_times * item.cost_price).toFixed(
                  2
                );
              }
              if (item.sale_price) {
                item.price_times = Number(
                  (item.sale_price / item.cost_price).toFixed(2)
                );
              }
            }
            return item;
          });
        } else {
          if (this.batchEditForm.price_times) {
            this.batchEditForm.sale_price = (
              this.batchEditForm.price_times * this.batchEditForm.cost_price
            ).toFixed(2);
          }
        }
      }
      if (data === "sale_price") {
        if (type === 1) {
          this.tableList = list.map((item, i) => {
            if (i + 1 === index) {
              if (item.cost_price) {
                item.price_times = (item.sale_price / item.cost_price).toFixed(
                  2
                );
              }
            }

            return item;
          });
        } else {
          if (this.batchEditForm.cost_price) {
            this.batchEditForm.price_times = (
              this.batchEditForm.sale_price / this.batchEditForm.cost_price
            ).toFixed(4);
          }
        }
      }
      this.noWatch = false;
      // 自动计算 此处需要节流
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setTimeout(() => {}, 500);
    },
    /*
     * 下载导入模板
     */
    getImportFile() {
      console.log(this.form);
      getImportFile({
        goods_type_id: this.form.goods_type_id,
        goods_number_type: this.goods_type,
      }).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        a.setAttribute("download",  this.goodsTypeName+`入库导入模板.xlsx`);
        a.href = URL.createObjectURL(blob);
        a.click();
      });
    },
    /*
     *  批量修改行
     */
    handleBatchEdit() {
      if (this.selectGoodsList.length === 0) {
        this.$message.error("请勾选需修改的货品");
      } else {
        this.batchEditVisiable = true;
      }
    },
    /*
     *  关闭批量修改
     */
    closeBatchEdit() {
      this.batchEditVisiable = false;
      this.batchEditForm = { ...this.filed };
    },
    /*
     *  批量修改确定
     */
    handleBatchEditCommit() {
      this.selectGoodsList.forEach((items) => {
        const info = { ...this.batchEditForm };
        Object.keys(items).forEach((key) => {
          if (info[key] !== "" && info[key] !== undefined) {
            items[key] = info[key];
          }
        });
      });
      this.batchEditVisiable = false;
      this.batchEditForm = { ...this.filed };
    },
    /*
     *  批量复制行
     */
    handleBatchCopy() {
      if (this.selectGoodsList.length === 0) {
        this.$message.error("请勾选需修改的货品");
      } else if (this.selectGoodsList.length > 1) {
        this.$message.error("请勾选单个货品数据进行批量复制");
      } else {
        this.batchCopyVisiable = true;
      }
    },
    /*
     *  关闭批量复制
     */
    closeBatchCopy() {
      this.batchCopyVisiable = false;
      this.copyNum = null;
    },
    /*
     *  批量复制确定
     */
    handleBatchCopyCommit() {
      const info = { ...this.selectGoodsList[0] };
      if (info.entry_goods_id) {
        delete info.entry_goods_id;
      }
      info.goods_number = null;
      const list = this.tableList;
      for (let i = 0; i < this.copyNum; i++) {
        info.index = this.tableList[this.tableList.length - 1].index + 1;
        list.push({ ...info });
      }
      this.tableList = list;
      this.closeBatchCopy();
    },
    /*
     *  返回
     */
    goBack() {
      this.$emit("hide");
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr, span) {
        arr.forEach((item) => {
          if (span || span === 0) {
            item.span = span + 1;
            item.goods_type_name = "├─" + item.goods_type_name;
          } else {
            item.span = 0;
          }
          newArr.push(item);
          if (item.children) {
            poling(item.children, item.span);
          }
        });
      }
      // 深复制
      const aArr = JSON.parse(JSON.stringify(arr));
      poling(aArr);
      return newArr;
    },
    /*
     *  获取仓库下拉数据
     */
    getWarehouseList() {
      getWarehouseList().then((res) => {
        if (res.code === 1) {
          this.wareHouseList = res.data;
        }
      });
    },
    /*
     *   获取供应商下拉数据
     */
    getSupplierList() {
      getSupplierList().then((res) => {
        if (res.code === 1) {
          this.supplierList = res.data;
        }
      });
    },
    /*
     *  删除
     */
    handleDele(goods_index, id) {
      this.tableList.forEach((item, index) => {
        if (index + 1 === goods_index) {
          this.tableList.splice(index, 1);
        }
      });
      if (this.tableList.length === 0) {
        this.tableList.push({
          ...this.filed,
        });
      }
    },
    /*
     *  生成入库单号
     */
    getEntryNumber() {
      getEntryNumber().then((res) => {
        this.entry_number = res.data;
      });
    },
    /*
     *  根据品类下拉获取品类字段
     */
    handleGoodsType(e,item) {
      this.getAttrList(e);
      this.goodsTypeName = this.goodsTypeInfo[e];
    },
    hanldeCellClick(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row.index;
      coordinate.column = column.index;
      this.coordinate = coordinate;
    },
    getCellIndex({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex + 1;
      column.index = columnIndex;
    },
    /*
     *  获取品类字段
     */
    getAttrList(id) {
      const data = {
        goods_type_id: id,
        goods_number_type: this.goods_type,
        isEntry: 1,
      };
      getAttrList(data).then((res) => {
        let newFiled = {};
        let len = res.data.length;
        this.fieldList = res.data;
        let newArr = res.data.map((item, index) => {
          item.prop = String(item.attr_fields);
          item.label = String(item.attr_name);
          newFiled["index"] = 0;
          newFiled[item.attr_fields] = "";
          this.filed[item.attr_fields] = "";
          return item;
        });
        const newList = [];
        newList.push(newFiled);
        this.goodsinfo_map = { ...newFiled };

        this.tableList = [...newList];
        this.rowList = [...newArr];
        this.batchEditForm = { ...this.filed };
      });
    },
    /*
     *  第一步
     */
    toFirst() {
      this.isFirst = true;
      this.isSecond = false;
    },
    /*
     *  第二步
     */
    toSecond() {
      if (!this.form.goods_type_id) {
        this.$message.error("请选择品类");
      } else if (!this.form.warehouse_id) {
        this.$message.error("请选择仓库");
      } else {
        this.isFirst = false;
        this.isSecond = true;
        if (this.type === "edit" || this.type === "sockEdit") {
          this.getEntryGoodsList();
        }
      }
    },
    /*
     *  新增数据
     */
    handleAddTable() {
      this.tableList.push({
        ...this.filed,
      });
    },
    /*
     *  提交入库
     */
    submit(formName) {
      this.submitLoading = true;
      this.tableLoading = true;
      const entryInfo = {
        supplier_id: this.form.supplier_id,
        note: this.form.note,
        warehouse_id: this.form.warehouse_id,
        entry_number: this.form.entry_number,
        goods_number_type: this.goods_type,
        goods_type_id: this.form.goods_type_id,
      };
      if (this.type === "edit" || this.type === "sockEdit") {
        entryInfo.entry_id = this.entry_id;
        entryInfo.hierarchy_id = this.form.hierarchy_id;
      }
      const newData = {
        // ...data,
        entry_info_json: JSON.stringify({
          entryInfo,
          goodsList: this.tableList,
        }),
      };
      if (this.type === "sockEdit") {
        newData.create_time = this.form.create_time;
        newData.audit_time = this.form.audit_time;
      }
      if (this.type === "add") {
        createWareHouse(newData)
          .then((res) => {
            if (res.code === 1) {
              this.entry_number = res.data.entry_number;
              this.isFirst = false;
              this.isSecond = false;
              this.isThird = true;
              this.submitLoading = false;
              this.tableLoading = false;
            }
          })
          .catch((res) => {
            this.submitLoading = false;
            this.tableLoading = false;
          });
      } else if (this.type === "sockEdit") {
        reverseCheckEntry(newData)
          .then((res) => {
            if (res.code === 1) {
              this.entry_number = res.data.entry_number;
              this.isFirst = false;
              this.isSecond = false;
              this.goBack();
              this.submitLoading = false;
              this.tableLoading = false;
            }
          })
          .catch((res) => {
            this.submitLoading = false;
            this.tableLoading = false;
          });
      } else {
        updateEntry(newData)
          .then((res) => {
            if (res.code === 1) {
              this.isFirst = false;
              this.isSecond = false;
              this.goBack();
              this.submitLoading = false;
              this.tableLoading = false;
            }
          })
          .catch((res) => {
            this.submitLoading = false;
            this.tableLoading = false;
          });
      }
    },
    change() {
      this.$forceUpdate();
    },
    cancelTemplateTable() {
      this.templateVisiable = false;
    },
    handelAddTemplate() {
      this.fieldList.forEach((item) => {
        this.downFiledList.forEach((items) => {
          const name =
            items.name.indexOf("*") != -1
              ? items.name.split("*")[1]
              : items.name;
          if (item.attr_name === name) {
            this.goodsinfo_map[item.attr_fields] = item.attr_name;
          }
        });
      });
      this.tableVisiable = true;
      this.templateVisiable = false;
      this.loading = false;
      this.template_name = "";
      this.templateName = "新增表头模板";
    },
    /*
     *  选择对应的表头模板
     */
    handleChooseTemplate(data) {
      this.goods_type_import_id = data.goods_type_import_id;
      this.templateName = data.template_name;
      getTemplateInfo({ goods_type_import_id: data.goods_type_import_id }).then(
        (res) => {
          if (res.code === 1) {
            this.tableVisiable = true;
            this.templateVisiable = false;
            this.goodsinfo_map = res.data.fields_map[0];
            Object.keys(res.data.fields_map[0]).forEach((item) => {
              let isExit = false;
              this.downFiledList.forEach((items) => {
                if (
                  res.data.fields_map[0][item] === items.name ||
                  "*" + res.data.fields_map[0][item] === items.name
                ) {
                  isExit = true;
                }
              });
              if (isExit === false) {
                res.data.fields_map[0][item] = "";
              }
            });
          }
        }
      );
    },
    /*
     *  删除表头模板
     */
    handleDelTemplate(data) {
      const info = {
        goods_type_import_id: data.goods_type_import_id,
        type: "delete",
      };
      this.$confirm("确定要删除该表头模板吗？删除后将无法恢复。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        addTemplateInfo(info).then((res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "删除成功！",
            });
            this.getTemplateList();
          }
        });
      });
    },
    /*
     *  获取表头模板列表
     */
    getTemplateList() {
      templateList({ page: 1 }).then((res) => {
        if (res.code === 1) {
          this.templateList = res.data.list;
          this.templateTotal = res.data.total;
        }
      });
    },
    handleCurrentChange(val) {
      templateList({ page: val }).then((res) => {
        if (res.code === 1) {
          this.templateList = res.data.list;
          this.templateTotal = res.data.total;
        }
      });
    },
    /*
     *  导入货品数据
     */
    handleGoodsSuccess(res, file) {
      if (res.code === 1) {
        this.templateVisiable = true;
        this.getTemplateList();
        this.customerTitle = res.data.customerTitle;
        this.downFiledList = res.data.customerTitle.map((item, index) => {
          var info = {};
          info.name = String(item);
          info.disabled = false;
          return info;
        });
        console.log(this.downFiledList, "downFiledList");
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },
    /*
     *  新增配置表头模板
     */
    handleConfigTemplate(type) {
      if (this.templateName === "新增表头模板") {
        if (this.template_name === "") {
          this.$message({
            type: "error",
            message: "请填写模板名称",
          });
        } else {
          const data = {
            goods_type_id: this.form.goods_type_id,
            template_name: this.template_name,
            goodsinfo_map: [this.goodsinfo_map],
          };

          this.loading = true;
          addTemplateInfo(data)
            .then((res) => {
              if (res.code === 1) {
                if (type === 10) {
                  this.tableVisiable = false;
                  this.templateVisiable = true;
                  this.goodsinfo_map = {};
                  this.loading = false;
                  this.getTemplateList();
                } else if (type === 20) {
                  this.goods_type_import_id = res.data;
                  this.handleSureTable(20);
                }
              } else {
                this.loading = false;
              }
            })
            .catch((res) => {
              this.loading = false;
            });
        }
      }
    },
    /*
     *  表头设置
     */
    handleSureTable(type) {
      this.loading = true;
      delete this.goodsinfo_map.index;
      const data = {
        goods_type_import_id: this.goods_type_import_id,
        reload: 1,
        goods_type_id: this.form.goods_type_id,
        goods_number_type: this.goods_type,
        goodsinfo_map: [this.goodsinfo_map],
        goods_number_type: this.goods_type,
        source: "list",
      };
      if (type === 10) {
        data.isSave = 0;
      }
      importGoodsDataByUploadFile(data)
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableVisiable = false;
            this.templateName = "";
            this.goods_type_import_id = 0;
            this.goodsinfo_map = {};
            if (this.tableList[0].goods_number === "") {
              this.tableList = res.data.entryList;
            } else {
              this.tableList.push(...res.data.entryList);
            }
            this.noWatch = false;
          } else {
            this.loading = false;
          }
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    handleDisableFileList() {
      for (let item in this.goodsinfo_map) {
        this.downFiledList = this.downFiledList.map((item, index) => {
          if (index === Number(this.goodsinfo_map[item])) {
            item.disabled = true;
          }
          return item;
        });
      }
    },
  },
};
</script>

<style   lang="less"scoped>
/deep/ .el-table th {
  padding: 0px;
}
.title {
  display: flex;
  flex-direction: row;
  height: 40px;
  line-height: 40px;
  font-weight: 600;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 13px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.box {
  background-color: white;

  .wareTitle {
    display: flex;
    line-height: 40px;

    .wareNum {
      margin-right: 10px;
      width: 40px;
      height: 40px;
      text-align: center;
      color: white;
      font-size: 16px;
      background: #c0c4cc;
      border-radius: 50%;
    }

    .selectNum {
      background: #2791ff;
    }
  }

  .form {
    margin-top: 20px;
    height: 300px;
  }

  .firstLine {
    padding: 20px 0 0 25px;
    background-color: white;
    height: 100%;
  }
  .adopt {
    padding: 20px 0 0 25px;
    border: 1px solid #ddd;
    height: 60px;
    background: linear-gradient(0deg, #f3f9ff, #ffffff);
  }
  .wareLine {
    border: 1px solid #e8e8e8;
    height: 60px;
    padding: 20px 0 0 25px;
    line-height: 60px;
    background: #f6f7fb;
  }

  .wareTable_top {
    width: 100%;

    display: flex;
    height: 50px;
    background: #f5f6fa;

    .wareTable_line {
      text-align: center;
      line-height: 50px;
      color: #909399;
    }
  }

  .wareTable_form {
    width: 100%;
    display: flex;
    height: 50px;
  }
}
</style>
