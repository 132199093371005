<template>
  <div>
    <div class="content">
      <div class="card">
        <el-page-header @back="goBack" content="入库审核"></el-page-header>
      </div>
    </div>
    <div class="content" style="margin-top: 20px">
      <div class="title">
        <div class="span"></div>
        基本信息
      </div>
      <div class="top">
        <div class="item" v-for="item in arr" :key="item.id">
          <div class="item-left">{{ item.name }}</div>
          <div class="item-right">{{ item.value }}</div>
        </div>
      </div>
    </div>

    <div class="content" style="margin-top: 20px">
      <div class="title">
        <div class="span"></div>
        货品详细信息
      </div>
      <div class="table" v-if="rowList.length !== 0">
        <el-table
          :data="tableData"
          stripe
          :row-class-name="handleRow"
          :height="600"
        >
          <template v-for="(item, index) in rowList">
            <el-table-column
              :key="index"
              :label="item.label"
              :prop="item.prop"
              align="center"
            >
              <template v-slot="scope">
                <template v-if="item.isImg || item.prop === 'goods_pic'">
                  <el-image
                    :src="scope.row[item.prop]"
                    :preview-src-list="[scope.row[item.prop]]"
                  >
                    <div slot="error" class="image-goods">
                      <img src="../../../../../assets/images/no_img.png" />
                    </div>
                  </el-image>
                </template>
                <template v-else>
                  <span v-if="scope.row.index % 2">{{
                    scope.row[item.prop]
                  }}</span>
                  <span
                    v-else
                    :class="
                      scope.row[item.prop] ===
                      tableData[scope.row.index - 2][item.prop]
                        ? ''
                        : scope.row.label === '修改'
                        ? 'red'
                        : ''
                    "
                  >
                    {{ scope.row[item.prop] }}
                  </span>
                </template>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <div class="pagina">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            :total="total"
            :current-page="page"
            :page-size="$store.state.pageSizes[0]"
            :page-sizes="$store.state.pageSizes"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div class="auditBut">
      <el-button
        type="primary"
        style="margin-right: 20px"
        :loading="auditLoading"
        @click="handleAudit(20)"
        >审核通过</el-button
      >
      <el-button
        type="primary"
        plain
        @click="handleAudit(30)"
        :loading="auditLoading"
        >审核不通过</el-button
      >
    </div>
    <el-dialog
      :visible.sync="sureVisiable"
      width="30%"
      title="审核通过"
      :destroy-on-close="true"
      :before-close="cancelAudit"
    >
      <div>确定审核通过吗？</div>
      <div style="text-align: right">
        <el-button type="primary" @click="commitAudit()">提交</el-button>
        <el-button @click="cancelAudit">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="auditVisiable"
      width="30%"
      title="审核不通过原因"
      :destroy-on-close="true"
      :before-close="cancelAudit"
    >
      <el-form
        ref="auditForm"
        :model="auditForm"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="原因说明" prop="remark">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入审核不通过原因说明"
            v-model="auditForm.remark"
          ></el-input>
        </el-form-item>
        <div style="text-align: right">
          <el-button type="primary" @click="commitAudit()">提交</el-button>
          <el-button @click="cancelAudit">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEntryInfo,
  getAttrList,
  entryLog,
  getEntryGoodsList,
  audit,
  getAttrListDesc,
} from "@/api/goods/goodsWarehousing/warehousing";
export default {
  data() {
    return {
      rowList: [],
      tableData: [],
      page: 1,
      page_num: 50,
      total: 0,
      auditVisiable: false,
      auditForm: {},
      auditLoading: false,
      sureVisiable: false,
      entry_status: "",
      arr: [
        {
          id: 0,
          name: "品类名称",
          value: "",
          prop: "goods_type_name",
        },
        {
          id: 1,
          name: "入库单号",
          value: "",
          prop: "entry_number",
        },
        {
          id: 2,
          name: "入库数量",
          value: "",
          prop: "total_num",
        },
        {
          id: 3,
          name: "审核状态",
          value: "",
          prop: "entry_status",
        },
        {
          id: 4,
          name: "添加人",
          value: "",
          prop: "create_user",
        },
        {
          id: 5,
          name: "添加时间",
          value: "",
          prop: "create_time",
        },
        {
          id: 6,
          name: "成本总金额",
          value: "",
          prop: "total_cost_price",
        },
        {
          id: 7,
          name: "审核人员",
          value: "",
          prop: "audit_user",
        },
        {
          id: 8,
          name: "审核时间",
          value: "",
          prop: "audit_time",
        },
        {
          id: 9,
          name: "备注",
          value: "",
          prop: "note",
        },
      ],
      rules: {
        remark: [
          {
            required: true,
            message: "请填写审核不通过原因",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    entry_id: Number,
  },
  created() {
    this.getEntryInfo();
    this.getEntryGoodsList();
  },
  methods: {
    /*
     *  返回
     */
    goBack() {
      this.$emit("hide");
    },
    /*
     *  获取入库单详情
     */
    getEntryInfo() {
      getEntryInfo({ entry_id: this.entry_id }).then((res) => {
        if (res.code === 1) {
          this.getAttrList(res.data.goods_type_id, res.data.goods_number_type);
          this.arr.forEach((item) => {
            item.value = res.data[item.prop] ? res.data[item.prop] : "--";
          });
        }
      });
    },
    handleRow({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    cancelAudit() {
      this.sureVisiable = false;
      this.auditVisiable = false;
    },
    /*
     *  提交审核失败原因
     */
    submitAudit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.commitAudit();
        }
      });
    },
    /*
     *  获取品类字段名称
     */
    getAttrList(id, goods_number_type) {
      const data = {
        goods_type_id: id,
        goods_number_type,
        isEntry: 1,
      };
      getAttrListDesc(data).then((res) => {
        let newFiled = {};
        let newArr = res.data.map((item) => {
          item.prop = item.goods_fields;
          item.label = item.goods_title;
          return item;
        });
        this.rowList = [{ prop: "index", label: "序号" }, ...newArr];
        console.log(this.rowList);
      });
    },
    /*
     *  品类数据分页
     */
    handleCurrentChange(val) {
      this.page = val;
      this.getEntryGoodsList();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.page_num = val;
      this.getEntryGoodsList();
    },
    /*
     *  获取品类数据
     */
    getEntryGoodsList() {
      getEntryGoodsList({
        entry_id: this.entry_id,
        page: this.page,
        page_num: this.page_num,
      }).then((res) => {
        if (res.code === 1) {
          res.data.list.map((item) => {
            for (var key in item) {
              if (!item[key]) {
                item[key] = "--";
              }
            }
            return item;
          });
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  审核
     */
    handleAudit(status) {
      this.entry_status = status;
      if (status === 20) {
        this.sureVisiable = true;
      } else {
        this.auditVisiable = true;
      }
    },
    /*
     *  提交审核
     */
    commitAudit() {
      this.sureVisiable = false;
      this.auditLoading = true;
      const data = {
        entry_id: this.entry_id,
        entry_status: this.entry_status,
        note_fail: this.auditForm.remark,
      };
      audit(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.auditLoading = false;
          this.goBack();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  width: 100%;
  height: 60px;
  line-height: 60px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
/deep/.auditBut {
  margin: 20px auto;
  width: 400px;
}
/deep/ .newly {
  padding: 20px;
}
.image-goods {
  width: 54px;
  height: 54px;

  img {
    width: 54px;
    height: 54px;
    object-fit: cover;
    margin: 5px auto 0 auto;
  }
}
/deep/ .top {
  display: flex;
  padding: 0px 50px 10px 50px;
  background: white;
  border-radius: 6px;
  flex-wrap: wrap;
  .item {
    display: flex;
    margin-bottom: 20px;
    width: 33%;
    &-left {
      width: 100px;
      color: #909399;
      font-size: 16px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #2791ff;
        position: absolute;
        top: 10px;
        left: -15px;
      }
    }
    &-right {
      color: #565656;
      font-size: 16px;
    }
  }
}
</style>