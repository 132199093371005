<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="详情页面"></el-page-header>
    </div>

    <storageDetail
      @onPrint="handlePrint"
      :showPrint="showPrint"
      :arr="arr"
      :goodsTotal="goodsTotal"
      :rowList1="rowList1"
      :tableData1="tableData1"
      :rowList2="rowList2"
      :tableData2="tableData2"
      @onChangePage1="handlePage"
      @onChangePage2="handlePage2"
      @onSizeChange="handleSizeChange"
      @onSizeChange2="handleSizeChange2"
      :exportUrl="'store/goods.EntryCtl/exportExcel'"
      :exportData="{
        entry_id: entry_id,
      }"
      :exportName="'入库单详情导出文件'"
      :copy="true"
    />
    <PrintConf
      function_code="entryExport"
      @onRowList="handlePrintRow"
      v-show="false"
    />
    <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
  </div>
</template>

<script>
import storageDetail from "@/components/goods/detail/storageDetail.vue";
import {
  getEntryInfo,
  getAttrList,
  entryLog,
  getEntryGoodsList,
  getAttrListDesc,
  getEntryPrintReq,
} from "@/api/goods/goodsWarehousing/warehousing";
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";
export default {
  data() {
    return {
      localPrintVisible: false,
      printData: {},
      printRows: [],
      page: 1,
      logPage: 1,
      limit: this.$store.state.pageSizes[0],
      page_num: this.$store.state.pageSizes[0],
      goodsTotal:{},
      arr: [
        {
          id: 0,
          name: "品类名称",
          value: "",
          prop: "goods_type_name",
        },
        {
          id: 1,
          name: "入库单号",
          value: "",
          prop: "entry_number",
        },
        // {
        //   id: 2,
        //   name: "入库数量",
        //   value: "",
        //   prop: "total_num",
        // },
        {
          id: 3,
          name: "审核状态",
          value: "",
          prop: "entry_status",
        },
        {
          id: 4,
          name: "创建人",
          value: "",
          prop: "create_user",
        },
        {
          id: 5,
          name: "创建时间",
          value: "",
          prop: "create_time",
        },
        // {
        //   id: 6,
        //   name: "总成本价",
        //   value: "",
        //   prop: "total_cost_price",
        // },
        {
          id: 7,
          name: "审核人员",
          value: "",
          prop: "audit_user",
        },
        // {
        //   id: 8,
        //   name: "总金重",
        //   value: "",
        //   prop: "total_gold_weight",
        // },
        // {
        //   id: 9,
        //   name: "总标签价",
        //   value: "",
        //   prop: "total_sale_price",
        // },
        // {
        //   id: 9,
        //   name: "总数量",
        //   value: "",
        //   prop: "total_num",
        // },
        // {
        //   id: 10,
        //   name: "总重",
        //   value: "",
        //   prop: "total_weight",
        // },
        {
          id: 11,
          name: "审核时间",
          value: "",
          prop: "audit_time",
        },
        {
          id: 12,
          name: "备注",
          value: "",
          prop: "note",
        },
        {
          id: 13,
          name: "原因说明",
          value: "",
          prop: "note_fail",
        },
      ],
      rowList1: [],
      tableData1: {
        list: [],
        total: 0,
      },
      rowList2: [
        {
          label: "操作时间",
          prop: "create_time",
          width: 200,
        },
        {
          label: "操作记录",
          prop: "msg",
        },
        {
          label: "操作人",
          prop: "create_user",
          width: 200,
        },
      ],
      tableData2: {
        list: [],
        total: 0,
      },
    };
  },
  components: {
    PrintConf,
    LocalPrint,
    storageDetail
  },
  props: {
    entry_id: Number,
    showPrint: Boolean,
    title: String,
  },
  created() {
    this.getEntryInfo();
    this.getEntryGoodsList();
    this.entryLog();
  },
  methods: {
    handlePrint() {
      this.getEntryPrint({
        entry_id: this.entry_id,
      });
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    getEntryPrint(cond) {
      getEntryPrintReq({ ...cond }).then((res) => {
        if (res.code === 1) {
          res.data.list.forEach((item, index) => {
            item.index = index + 1;
          });
          res.data.top = [];
          const data = res.data.data;
          const arr1 = [
            { txt: "创建人", value: data.create_realname, width: "24%" },
            { txt: "创建时间", value: data.create_time, width: "24%" },
            { txt: "入库单号", value: data.entry_number, width: "30%" },
          ];
          const arr2 = [
            { txt: "门店", value: data.short_name, width: "24%" },
            { txt: "供应商", value: data.supplier_name, width: "24%" },
            { txt: "仓库", value: data.warehouse_name, width: "24%" },
          ];
          res.data.bottom = [];
          const arr3 = [
            { txt: "备注", value: data.allot_remark, width: "100%" },
          ];
          const arr4 = [
            { txt: "审核人", value: data.audit_realname, width: "24%" },
            {
              txt: "审核时间",
              value: data.audit_time ? data.audit_time : "",
              width: "30%",
            },
          ];
          res.data.top.push(arr1, arr2);
          res.data.bottom.push(arr3, arr4);
          // 计算合计
          this.printData = res.data;
          const sum = {
            index: "合计",
          };
          const decimal = {};
          this.printData.list.forEach((item) => {
            this.printRows.forEach((row) => {
              if (row.is_sum) {
                if (!sum[row.field_alias]) {
                  sum[row.field_alias] = 0;
                }
                if (item[row.field_alias]) {
                  sum[row.field_alias] += Number(item[row.field_alias]);
                  if (!decimal[row.field_alias]) {
                    const arr = String(item[row.field_alias]).split(".");
                    if (arr.length === 2) {
                      decimal[row.field_alias] = arr[1].length;
                    } else {
                      decimal[row.field_alias] = 0;
                    }
                  }
                }
              }
            });
          });
          // 需要保留多少位小数
          Object.keys(sum).forEach((item) => {
            if (item !== "index") {
              sum[item] = sum[item].toFixed(decimal[item]);
            }
          });
          this.printData.list.push(sum);
          this.localPrintVisible = true;
        }
      });
    },
    /*
     *  获取入库单详情
     */
    getEntryInfo() {
      getEntryInfo({ entry_id: this.entry_id }).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.getAttrList(res.data.goods_type_id, res.data.goods_number_type);
          this.arr.forEach((item) => {
            item.value = res.data[item.prop] ? res.data[item.prop] : "--";
          });

        }
      });
    },
    /*
     *  获取品类字段名称
     */
    getAttrList(id, goods_number_type) {
      const data = {
        goods_type_id: id,
        // goods_number_type,
        // isEntry: 1,
      };
      getAttrListDesc(data).then((res) => {
        let newFiled = {};
        let newArr = res.data.map((item) => {
          item.prop = item.goods_fields;
          item.label = item.goods_title;
          return item;
        });
        this.rowList1 = [{ prop: "index", label: "序号" }, ...newArr];
      });
    },
    /*
     *   返回
     */
    goBack() {
      this.$emit("hide");
    },
    handleSizeChange(page_num) {
      this.page = 1;
      this.page_num = page_num;
      this.getEntryGoodsList();
    },
    handleSizeChange2(limit) {
      this.page = 1;
      this.limit = limit;
      this.getLogList();
    },

    handlePage(page) {
      this.page = page;
      this.getEntryGoodsList();
    },
    handlePage2(page) {
      this.logPage = page;
      this.entryLog();
    },
    /*
     *  日志
     */
    entryLog() {
      const data = {
        entry_id: this.entry_id,
        page: this.logPage,
        page_num: this.limit,
      };
      entryLog(data).then((res) => {
        if (res.code === 1) {
          res.data.list.map((item) => {
            for (var key in item) {
              if (!item[key]) {
                item[key] = "--";
              }
            }
            return item;
          });
          this.tableData2.list = res.data.list || [];
        }
      });
    },
    /*
     *  获取品类数据
     */
    getEntryGoodsList() {
      getEntryGoodsList({
        entry_id: this.entry_id,
        page: this.page,
        page_num: this.page_num,
      }).then((res) => {
        if (res.code === 1) {
          res.data.list.map((item) => {
            for (var key in item) {
              if (!item[key]) {
                item[key] = "--";
              }
            }
            return item;
          });
          this.tableData1.list = res.data.list;
          this.tableData1.total = res.data.total;
          this.goodsTotal=res.data.sum;
        }
      });
    },
  },
};
</script>
