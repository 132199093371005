<template>
  <div>
    <div v-if="!isDetails && !isAdd && !isAudit" class="content">
      <div class="card" style="display: flex">
        <div class="left">
          <el-button type="primary" @click="handleAdd" icon="el-icon-plus"
            >新增入库</el-button
          >
          <el-button
            :loading="exportLoading"
            type="primary"
            plain
            @click="handleExport"
            icon="el-icon-upload2"
            >导出</el-button
          >
        </div>
        <div class="line"></div>
        <div class="middle" style="flex: 1">
          <Cond @onCond="handleCond" function_code="entry" />
        </div>
        <div class="right" style="display: flex">
          <TableConf function_code="entry" @onRowList="handleRowList" />
          <PrintConf function_code="entryExport" @onRowList="handlePrintRow" />
        </div>
      </div>
      <div class="table" v-if="rowList.length">
        <el-table
          :data="tableData"
          stripe
          height="700"
          v-loading="loading"
          :element-loading-text="delTip"
          @row-dblclick="handleDalclick"
        >
          <template v-for="(item, index) in rowList">
            <el-table-column
              v-if="item.field_alias === 'status_name'"
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <div>
                  <div
                    v-if="scope.row.status_name === '审核不通过'"
                    style="color: #fd563a"
                  >
                    {{ scope.row.status_name }}
                  </div>
                  <div
                    v-else-if="scope.row.status_name === '审核通过'"
                    style="color: #21bc36"
                  >
                    {{ scope.row.status_name }}
                  </div>
                  <div v-else>
                    {{ scope.row.status_name }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
            </el-table-column>
          </template>
          <el-table-column
            align="center"
            fixed="right"
            prop="operation"
            label="操作"
            width="200"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleDetails($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <!-- <el-option
                    label="打印单据"
                    :value="2"
                    v-if="scope.row.status_name === '审核通过'"
                  ></el-option> -->
                  <el-option
                    label="导入货品图片"
                    :value="3"
                    v-if="scope.row.status_name === '审核通过'"
                  ></el-option>
                  <el-option
                    label="编辑"
                    :value="4"
                    v-if="
                      scope.row.status_name === '审核不通过' ||
                      noSockIds.includes(scope.row.entry_id) === true
                    "
                  ></el-option>
                  <el-option
                    label="失败原因"
                    :value="5"
                    v-if="scope.row.status_name === '审核不通过'"
                  ></el-option>
                  <el-option
                    label="审核"
                    :value="6"
                    v-if="scope.row.status_name === '待审核'"
                  ></el-option>
                  <el-option label="导出货品数据" :value="7"></el-option>
                  <el-option label="打印" :value="8"></el-option>
                  <el-option
                    label="打印标签"
                    :value="9"
                    v-if="scope.row.entry_status === 20"
                  ></el-option>
                  <!-- <el-option
                    label="复制货号"
                    :value="10"
                    @click.native="copyCode"
                  ></el-option> -->
                  <el-option
                    label="解锁"
                    :value="20"
                    v-if="
                      scope.row.status_name === '审核通过' &&
                      noSockIds.includes(scope.row.entry_id) === false
                    "
                  ></el-option>
                  <el-option
                    label="删除"
                    :value="21"
                    v-if="
                      noSockIds.includes(scope.row.entry_id) === true ||
                      scope.row.status_name === '审核不通过'
                    "
                  ></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          :current-page="cond.page"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="$store.state.pageSizes[0]"
          :page-sizes="$store.state.pageSizes"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
      <LocalPrint
        :show="false"
        :printRows="printRows"
        :printData="printData"
        :title="title"
        :dialogVisible="localPrintVisible"
        @onClose="localPrintVisible = false"
      />
      <el-dialog
        :visible.sync="tableVisiable"
        title="设置表头"
        width="30%"
        :destroy-on-close="true"
        :before-close="cancelTable"
      >
        <el-table :data="rowList" stripe>
          <el-table-column align="center" prop="index" label="排序">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-top"
                v-if="scope.row.index !== 1"
              ></el-button>
              <el-button
                size="mini"
                icon="el-icon-bottom"
                v-if="scope.row.index !== 11"
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="label"
            label="表头字段"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="width"
            label="宽度(px)"
          ></el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog
        :visible.sync="addVisiable"
        title="选择入库类型"
        :destroy-on-close="true"
        width="30%"
        :before-close="cancelAdd"
      >
        <div
          style="
            width: 320px;
            margin: 0 auto;
            text-align: center;
            display: flex;
          "
        >
          <div style="width: 140px; margin-right: 40px" @click="toAdd(10)">
            <div
              class="box"
              :style="
                isGoodsType === 10
                  ? 'border-color: #2791ff;background: #f2f9ff;'
                  : null
              "
            >
              <img
                class="oneImg"
                src="../../../../assets/images/goods/icon_one.png"
              />
            </div>
            <div
              style="font-size: 16px; margin-top: 15px"
              :style="isGoodsType === 10 ? 'color: #2791ff' : null"
            >
              一码一货
            </div>
          </div>
          <div style="width: 140px" @click="toAdd(20)">
            <div
              class="box"
              :style="
                isGoodsType === 20
                  ? 'border-color: #2791ff;background: #f2f9ff;'
                  : null
              "
            >
              <img
                class="allImg"
                src="../../../../assets/images/goods/icon_multiple.png"
              />
            </div>
            <div
              style="font-size: 16px; margin-top: 15px"
              :style="isGoodsType === 20 ? 'color: #2791ff' : null"
            >
              一码多货
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <wareHousingDetails
      :showPrint="true"
      :title="title"
      v-if="isDetails === true"
      @hide="fetchData('back')"
      :entry_id="entry_id"
    />
    <wareHousingAdd
      v-if="isAdd === true"
      :type="type"
      @hide="fetchData()"
      :goods_type="goods_type"
      :entry_id="entry_id"
    />
    <wareHousingAudit
      v-if="isAudit === true"
      @hide="fetchData()"
      :entry_id="entry_id"
    />
    <el-dialog
      :visible.sync="errorVisible"
      width="30%"
      title="审核失败原因"
      :destroy-on-close="true"
      :before-close="cancelError"
    >
      <div>{{ audit_remark }}</div>
    </el-dialog>
    <el-dialog
      :visible.sync="billVisible"
      title="打印单据"
      :destroy-on-close="true"
      :before-close="cancelBill"
    >
      <div>
        <el-table :data="billTable" stripe>
          <el-table-column
            align="center"
            prop="goods_number"
            label="货号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="goods_name"
            label="品名"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="goods_style_number"
            label="款号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="102"
            label="产地"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="103"
            label="品牌"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="104"
            label="金重(g)"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="110"
            label="重量"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="sale_price"
            label="标签价"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="goodImgVisible"
      title="导入货品图片"
      :destroy-on-close="true"
      :before-close="cancelGoodImg"
    >
      <div>
        <el-form :model="goodImgForm">
          <div class="form">
            <el-form-item label="导入类型" label-width="150px">
              <el-radio-group v-model="import_pic_type">
                <el-radio :label="1">按货号导入</el-radio>
                <el-radio :label="2">按款号导入</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="上传图片压缩包"
              v-if="import_pic_type"
              label-width="150px"
            >
              <el-upload
                :action="baseUrl + '/store/goods.Goods/uploadGoodsPicByZip'"
                :data="{
                  import_pic_type: import_pic_type,
                }"
                :headers="{
                  authorization: token,
                }"
                :show-file-list="false"
                :on-success="handleImgSuccess"
              >
                <el-button type="primary"
                  >上传<i
                    class="el-icon-upload el-icon--right"
                  ></i></el-button></el-upload
            ></el-form-item>
          </div>
          <div style="text-align: right">
            <el-button type="primary" @click="cancelGoodImg">确定</el-button>
            <el-button @click="cancelGoodImg">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="printVisible"
      :before-close="beforeClose"
      title="打印标签"
      :destroy-on-close="true"
    >
      <Print :type="50" :order_id="currRow.entry_id" v-if="printVisible" />
    </el-dialog>
  </div>
</template>

<script>
import wareHousingDetails from "../list/details/index";
import wareHousingAdd from "../list/add/index";
import wareHousingAudit from "../list/audit/Index";
import {
  getList,
  getMerchantList,
  getGoodsTypeList,
  updateState,
  uploadGoodsPic,
  printEntryBill,
  exportEntryGoods,
  getEntryPrintReq,
  getEntryGoodsList,
  deleteEntry,
  deleteReverseEntry,
} from "@/api/goods/goodsWarehousing/warehousing";
import { getIsLock } from "@/api/monthSettle/index.js";
import storage from "good-storage";
import Cond from "@/components/cond/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";
import Print from "@/components/print/Index.vue";
import ClipboardJS from "clipboard";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      localPrintVisible: false,
      printData: {},
      title: "入库单",
      currRow: {},
      printRows: [],
      currRow: {},
      printVisible: false,
      currRow: {},
      copyString: "",
      exportLoading: false,
      cond: {
        page: 1,
        page_num: 50,
      },
      tableData: [],
      category_list: [],
      import_pic_type: null,
      store_list: [],
      total: 0,
      tableVisiable: false,
      errorVisible: false,
      addVisiable: false,
      hierarchy_type_id: null,
      isDetails: false,
      goodImgVisible: false,
      isAdd: false,
      isAudit: false,
      entry_id: 0,
      audit_remark: "",
      goods_type: 10,
      rowList: [],
      billVisible: false,
      billTable: [],
      goodImgForm: {},
      formLabelWidth: "150px",
      good_img: "",
      token: "",
      loading: false,
      delTip: "",
      isGoodsType: 0,
      baseUrl: "",
      noSockIds: [],
      type: "add",
    };
  },
  components: {
    wareHousingDetails,
    wareHousingAdd,
    wareHousingAudit,
    Cond,
    TableConf,
    PrintConf,
    LocalPrint,
    Print,
  },
  created() {
    this.token = storage.session.get("token") || Cookies.get("token");

    const env = process.env.NODE_ENV;
    const host = window.location.host;
    if (env === "development") {
      this.baseUrl = "https://api.test.etouch.vip/";
    } else {
      if (host === "edianbao.test.etouch.vip") {
        this.baseUrl = "https://api.test.etouch.vip/";
      }
      if (host === "www.etouch.top") {
        this.baseUrl = "https://api.etouch.top/";
      }
      if (host === "edianbao.pretest.etouch.vip") {
        this.baseUrl = "https://api.pretest.etouch.vip/";
      }
    }

    if (this.$route.query.entry_number) {
      this.cond.entry_number = this.$route.query.entry_number;
    }
    this.fetchData();
    if (storage.get("userInfo").hierarchy_type_id === 30) {
      this.hierarchy_type_id = storage.get("userInfo").hierarchy_type_id;
      this.getMerchantList();
    }
    this.getGoodsTypeList();
  },
  methods: {
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    beforeClose() {
      this.currRow.select = "";
      this.printVisible = false;
    },
    copyCode() {
      getEntryGoodsList({
        entry_id: this.currRow.entry_id,
        page: 1,
        page_num: 9999999,
      }).then((res) => {
        if (res.code === 1) {
          this.currRow.select = "";
          if (res.data.list.length) {
            res.data.list.forEach((item) => {
              this.copyString += `${item.goods_number} `;
              this.$copyText(this.copyString);
            });
            this.$message.success("复制成功");
          } else {
            this.$message.warning("没有可复制的货号");
          }
        }
      });
    },
    filterHandler(value, row, column) {
      const entry_status = {
        10: "待审核",
        20: "审核通过",
        30: "审核失败",
      };
      const goods_number_type = {
        10: "一码一货",
        20: "一码多货",
      };
      const { property } = column;
      return (
        row[property] ===
        (property === "status_name"
          ? entry_status[value]
          : goods_number_type[value])
      );
    },
    handleCond(cond) {
      this.cond = {
        ...cond,
        page: 1,
        page_num: this.cond.page_num,
      };
      this.$route.params.type = "search";
      this.fetchData();
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    /*
     *  入库列表
     */
    fetchData(type) {
      if (this.$route.params.type && !type) {
        if (this.$route.params.type === "detail") {
          this.isDetails = true;
          this.entry_id = this.$route.params.key;
        }
      } else {
        this.isDetails = false;
      }
      this.isGoodsType = 0;
      this.type = "add";
      this.addVisiable = false;
      this.isAdd = false;
      this.isAudit = false;
      this.loading = true;
      this.noSockIds = [];
      getList({ ...this.cond })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.map((item) => {
              for (var key in item) {
                if (!item[key]) {
                  item[key] = "--";
                }
              }
              return item;
            });
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    /*
     *  获取店铺下拉数据
     */
    getMerchantList() {
      getMerchantList().then((res) => {
        if (res.code === 1) {
          this.store_list = res.data;
        }
      });
    },
    /*
     *  获取品类数据
     */
    getGoodsTypeList() {
      getGoodsTypeList().then((res) => {
        if (res.code === 1) {
          this.category_list = res.data;
        }
      });
    },
    /*
     *  新增入库单
     */
    toAdd(type) {
      this.isGoodsType = type;
      this.goods_type = type;
      this.type = "add";
      this.isAdd = true;
    },
    /*
     *  导出入库数据
     */
    handleExport() {
      this.exportLoading = true;
      const data = {
        export: 1,
        function_code: "entry",
        ...this.cond,
      };
      getList(data)
        .then((res) => {
          this.exportLoading = false;
          var filename = "入库导出.csv";
          res = "\ufeff" + res;
          var blob = new Blob([res], { type: "application/vnd.ms-excel" });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            var urlCreator = window.URL || window.wibkitURL;
            var url = urlCreator.createObjectURL(blob);
            var a = document.createElement("a");
            a.download = filename;
            a.href = url;
            a.click();
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    /*
     *  导出货品数据
     */
    handleExportEntryGoods(item) {
      const data = {
        entry_id: item.entry_id,
      };
      exportEntryGoods(data).then((res) => {
        this.exportLoading = false;
        var filename = "货品数据导出.csv";
        res = "\ufeff" + res;
        var blob = new Blob([res], { type: "application/vnd.ms-excel" });
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          var urlCreator = window.URL || window.wibkitURL;
          var url = urlCreator.createObjectURL(blob);
          var a = document.createElement("a");
          a.download = filename;
          a.href = url;
          a.click();
        }
        this.fetchData();
      });
    },
    /*
     *  新增入库
     */
    handleAdd() {
      this.addVisiable = true;
      this.entry_id = 0;
    },
    /*
     *  检索
     */
    handleChange() {},
    /*
     *  分页操作
     */
    handleCurrentChange(val) {
      this.cond.page = val;
      const data = {
        ...this.cond,
      };

      getList(data).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  每页条数
     */
    handleSizeChange(val) {
      this.cond.page_num = val;
      const data = {
        page: this.cond.page,
        page_num: this.cond.page_num,
        ...this.cond,
      };
      getList(data).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  关闭列表配置弹窗
     */
    cancelTable() {
      this.tableVisiable = false;
    },
    handleDalclick(row) {
      this.audit_remark = row.note_fail;
      this.entry_id = row.entry_id;
      this.isDetails = true;
    },
    getEntryPrint(cond) {
      console.log(cond)
      getEntryPrintReq({ ...cond })
        .then((res) => {
          console.log(res);
          if (res.code === 1) {
            this.currRow.select = "";
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: "创建人", value: data.create_realname, width: "24%" },
              { txt: "创建时间", value: data.create_time, width: "24%" },
              { txt: "入库单号", value: data.entry_number, width: "30%" },
            ];
            const arr2 = [
              { txt: "门店", value: data.short_name, width: "24%" },
              { txt: "供应商", value: data.supplier_name, width: "24%" },
              { txt: "仓库", value: data.warehouse_name, width: "24%" },
              { txt: "品类", value: data.goods_type_name, width: "24%" },
            ];
            res.data.bottom = [];
            const arr3 = [{ txt: "备注", value: data.note, width: "100%" }];
            const arr4 = [
              { txt: "审核人", value: data.audit_realname, width: "24%" },
              {
                txt: "审核时间",
                value: data.audit_time ? data.audit_time : "",
                width: "30%",
              },
            ];
            res.data.top.push(arr1, arr2);
            res.data.bottom.push(arr3, arr4);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: "合计",
            };
            const decimal = {};
            console.log(this.printRows);
            // this.printData.list.forEach((item) => {
            //   this.printRows.forEach((row) => {
            //     if (row.is_sum) {
            //       if (!sum[row.field_alias]) {
            //         sum[row.field_alias] = 0;
            //       }
            //       if (item[row.field_alias]) {
            //         sum[row.field_alias] += Number(item[row.field_alias]);
            //         if (!decimal[row.field_alias]) {
            //           const arr = String(item[row.field_alias]).split(".");
            //           if (arr.length === 2) {
            //             decimal[row.field_alias] = arr[1].length;
            //           } else {
            //             decimal[row.field_alias] = 0;
            //           }
            //         }
            //       }
            //     }
            //   });
            // });
            // // 需要保留多少位小数
            // Object.keys(sum).forEach((item) => {
            //   if (item !== "index") {
            //     sum[item] = sum[item].toFixed(decimal[item]);
            //   }
            // });
            res.data.sum.index="合计";
            this.printData.list.push(res.data.sum);
            this.localPrintVisible = true;
          }
        })
        .catch(() => {
          this.currRow.select = "";
        });
    },
    accAdd(arg1,arg2){
			var r1,r2,m;
			//获取arg1,arg2的小数点后的长度;
			try{
				r1=arg1.toString().split(".")[1].length;
			}catch(e){
				r1=0;
			}
			try{
				r2=arg2.toString().split(".")[1].length;
			}catch(e){
				r2=0;
			}
			m=Math.pow(10,Math.max(r1,r2));
			/**需要引入其他的方法
			 * return m.div(parseFloat(arg1).mul(m)+parseFloat(arg2).mul(m))
			 **/
			return (arg1*m+arg2*m)/m;
		},
    /*
     *  删除
     */
    handelCanceled(entry_id) {
      this.$confirm("确定要删除该入库单？删除后将无法恢复。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.delTip = "请稍等，正在删除该入库单中...";
          if (this.noSockIds.includes(entry_id) === true) {
            deleteReverseEntry({
              entry_id,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message({
                    type: "success",
                    message: "删除成功！",
                  });
                  this.loading = false;
                  this.delTip = "";
                  this.fetchData();
                } else {
                  this.loading = false;
                  this.delTip = "";
                  this.fetchData();
                }
              })
              .catch((res) => {
                this.loading = false;
                this.delTip = "";
                this.fetchData();
              });
          } else {
            deleteEntry({
              entry_id,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message({
                    type: "success",
                    message: "删除成功！",
                  });
                  this.loading = false;
                  this.delTip = "";
                  this.fetchData();
                } else {
                  this.loading = false;
                  this.delTip = "";
                  this.fetchData();
                }
              })
              .catch((res) => {
                this.loading = false;
                this.delTip = "";
                this.fetchData();
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.loading = false;
          this.delTip = "";
          this.fetchData();
        });
    },
    /*
     *  查看详情
     */
    handleDetails(event, row) {
      this.currRow = row;
      if (event === 1) {
        this.isDetails = true;
      } else if (event === 6) {
        this.isAudit = true;
      } else if (event === 5) {
        this.errorVisible = true;
      } else if (event === 2) {
        this.billVisible = true;
        printEntryBill({ entry_id: row.entry_id }).then((res) => {
          if (res.code === 1) {
            this.billTable = res.data.goodsList.list;
          }
        });
      } else if (event === 3) {
        this.goodImgVisible = true;
      } else if (event === 4) {
        this.goods_type = row.goods_number_type;
        this.isAdd = true;
        if (this.noSockIds.includes(row.entry_id) === true) {
          this.type = "sockEdit";
        } else {
          this.type = "edit";
        }
      } else if (event === 7) {
        this.handleExportEntryGoods(row);
      } else if (event === 8) {
        this.getEntryPrint({
          entry_id: row.entry_id,
        });
      } else if (event === 9) {
        this.printVisible = true;
      } else if (event === 20) {
        getIsLock({
          source: "entry",
          primary_key_id: row.entry_id,
        })
          .then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "解锁成功！",
              });
              // this.addVisiable = true;
              this.type = "sockEdit";
              row.select = "";
              this.noSockIds.push(row.entry_id);
            }
          })
          .catch(() => {
            row.select = "";
          });
      } else if (event === 21) {
        this.handelCanceled(row.entry_id);
      }
      this.audit_remark = row.note_fail;
      this.entry_id = row.entry_id;
    },
    /*
     *  上传货品图片
     */
    handleImgSuccess(res, file) {
      if (res.code === 1) {
        this.$message({
          type: "success",
          message: "上传成功！",
        });
        this.good_url = res.data;
        this.good_img = URL.createObjectURL(file.raw);
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },
    /*
     *  保存货品图片
     */
    sumbitGoodImg() {
      // const data = {
      //   file: this.good_url,
      // };
      // uploadGoodsPic(data).then((res) => {
      //   if (res.code === 1) {
      //   }
      // });
    },
    /*
     *  关闭选择入库类型弹窗
     */
    cancelAdd() {
      this.addVisiable = false;
    },
    cancelError() {
      this.errorVisible = false;
      this.fetchData();
    },
    cancelBill() {
      this.billVisible = false;
      this.fetchData();
    },
    cancelGoodImg() {
      this.goodImgVisible = false;
      this.fetchData();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .avatar-uploader .el-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
/deep/ .avatar {
  width: 290px;
  height: 170px;
  display: block;
}
/deep/ .avatarOther {
  width: 100px;
  height: 100px;
  display: block;
}

/deep/ .auditSuccess {
  padding-bottom: 20px;
  margin-top: 10px;
  height: 450px;
  background-color: white;

  /deep/ .auditImg {
    margin: 10px auto 0 45%;
    width: 169px;
    height: 142px;
  }

  /deep/ .audit_text {
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;

    span {
      font-size: 22px;
      color: #5493f5;
    }
  }
}

.box {
  width: 140px;
  height: 160px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 6px;

  .oneImg {
    margin: 36px auto 0 auto;
    width: 80px;
    height: 89px;
  }

  .allImg {
    margin: 22px auto 0 auto;
    width: 96px;
    height: 105px;
  }
}
.box:hover {
  cursor: pointer;
}
</style>
